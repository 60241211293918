.selected-container {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .selected-container strong {
    font-size: 1.1rem;
  }

  .min-height-block {
    min-height: 400px; /* Establecer min-height solo en pantallas grandes */
}

@media (max-width: 768px) {
    .min-height-block {
        min-height: auto; /* Elimina el min-height en pantallas pequeñas */
    }
}

.div-carrusel{
  height: 400px;
}

@media (max-width: 1024px) {
  .div-carrusel{
    height: 300px;
  }
}

@media (max-width: 768px) {
  .div-carrusel{
    height: 200px;
  }
}